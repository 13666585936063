import React from 'react';
import '../css/projects.css'
import ProjectContainer from '../components/projectcontainer';

function Projects() {
    return (
      <div className="projectPage">
          <ProjectContainer />
      </div>
    );
  }
  
export default Projects;