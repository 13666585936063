import React from 'react';
import AboutContainer from '../components/aboutcontainer';
import '../css/about.css';

function About() {
    return (
      <div>
        <AboutContainer/>
      </div>
    );
  }
  
export default About;