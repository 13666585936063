import React from 'react';
import ResumeContainer from '../components/resumecontainer';
function Resume() {
    return (
      <div className="resumePage">
          <ResumeContainer />
      </div>
    );
  }
  
export default Resume;